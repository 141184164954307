<template>
  <div class="bk">
    <div class="coupon">
      <!-- 头部 -->
      <van-nav-bar
        title="我的代金劵"
        left-arrow
        @click-left="onClickLeft"
        fixed
        :z-index="101"
        right-text="兑换"
        @click-right="onClickRight"
      ></van-nav-bar>
      <!-- 优惠卷=劵列表 -->
      <van-tabs
        v-model="active"
        
        title-inactive-color="#333"
        class="dark_vant_black"
      >
        <van-tab
          :title="
            index === 'hasUsed'
              ? '已使用'
              : index === 'loseUsed'
              ? '已失效'
              : '未使用'
          "
          v-for="(item, index) in couponClass"
          :key="index"
        >
          <ul
            v-for="coupon in item"
            :key="coupon.id"
            class="coupon-item dark_shop_bg"
            :style="
              coupon.status == 2 ? '' : coupon.status == 0 ? bgUsed : bgTimeout
            "
            :class="{
              dark_used: coupon.status == 0,
              dark_timeout: coupon.status == 1,
            }"
          >
            <li
              class="coupon-item-info"
              :class="{
                bkimg: coupon.status == 0,
                bkimg1: coupon.status == 1,
              }"
            >
              <p class="leftpadding">
                <span class="coupon-value">{{
                  (coupon.discount / 100).toFixed(2)
                }}</span>
                <span class="coupon-unit">元</span>
              </p>
              <div>
                <p
                  class="dark_text text-top"
                  :class="{ active: item.status != 0 }"
                >
                  {{ coupon.title }}
                </p>
                <p class="dark_text" :class="{ active: item.status != 0 }">
                  {{ "有效期截至" }}
                </p>
                <p>
                  {{ coupon.time }}
                </p>
                <p
                  class="dark_text"
                  v-if="coupon.type !== 2"
                  :class="{ active: item.status != 0 }"
                >
                  {{ "满 : " + coupon.min / 100 + "元可用" }}
                </p>
                <p class="dark_text" :class="{ active: item.status != 0 }">
                  {{
                    "优惠卷类型 : " +
                    (+coupon.type === 1
                      ? "满减"
                      : coupon.type === 2
                      ? "代金卷"
                      : "火锅卷")
                  }}
                </p>
                <!-- <span class="coupon-time"
                >有效期:{{
                  coupon.createTime.split(" ")[0].replace(/\-/g, ".")
                }}-{{
                  coupon.expireTime.split(" ")[0].replace(/\-/g, ".")
                }}</span
              > -->
                <!-- <span>使用规则：满{{ coupon.startMoney }}元即可使用</span> -->
                <!-- <span style="display: block" v-if="item.status == 2"
                >使用日期：{{ coupon.usedTime }}</span
              > -->
              </div>
              <!-- <div class="bkurl">
                <img src="../../../../static/images/timeout.png" />
              </div> -->
            </li>
          </ul>

          <!-- <ul
          v-for="coupon in item"
          :key="coupon.id"
          class="coupon-item dark_shop_bg"
          :style="item.status == 1 ? '' : item.status == 2 ? bgUsed : bgTimeout"
          :class="{
            dark_used: item.status == 2,
            dark_timeout: item.status == 3,
          }"
        >
          <li class="coupon-item-info">
            <p :class="{ active: item.status != 1 }">
              <span class="coupon-value">{{ coupon.discountMax }}</span>
              <span class="coupon-unit">元</span>
            </p>
            <p>
              <span
                class="coupon-name dark_text"
                :class="{ active: item.status != 0 }"
                >{{ coupon.batch }}</span
              >
              <span class="coupon-time"
                >有效期:{{
                  coupon.createTime.split(" ")[0].replace(/\-/g, ".")
                }}-{{
                  coupon.expireTime.split(" ")[0].replace(/\-/g, ".")
                }}</span
              >
              <span>使用规则：满{{ coupon.startMoney }}元即可使用</span>
              <span style="display: block" v-if="item.status == 2"
                >使用日期：{{ coupon.usedTime }}</span
              >
            </p>
          </li>
        </ul> -->
          <p style="text-align: center" v-if="!item.length">
            <img
              src="../../../../static/images/error.png"
              alt="无数据占位图"
              width="50%"
            />
          </p>
        </van-tab>
      </van-tabs>
      <van-popup v-model="show" :style="{ height: '5rem', width: '80%' }" round>
        <div class="box">
          <p class="box-title">兑换</p>
          <van-field
            v-model="redeCode"
            label="兑换码"
            placeholder="请输入兑换码"
            label-width="1rem"
          />
          <van-field
            v-model="password"
            label="密码"
            placeholder="请输入密码"
            label-width="1rem"
          />
          <div class="btn">
            <van-button
              type="danger"
              round
              size="small"
              class="confirm"
              @click="exchangeCoupon()"
              :loading="loading"
              >确认兑换</van-button
            >
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import { Tab, Tabs, Popup, Field, Button, Toast } from "vant";
export default {
  data() {
    return {
      redeCode: "",
      password: "",
      active: 0,
      bgUsed: {
        // 已使用样式
        background: "url('../../../../static/images/used.png') no-repeat",
        backgroundSize: "contain",
        // backgroundColor: "#fff"
      },
      bgTimeout: {
        // 已过期样式
        background: "url('../../../../static/images/timeout.png') no-repeat",
        backgroundSize: "contain",
        // backgroundColor: "#fff"
      },
      couponClass: [], // 优惠劵分类
      show: false,
      loading: false,
    };
  },
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Popup.name]: Popup,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  created() {
    this.getCouponList();
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    onClickRight() {
      this.show = true;
    },
    exchangeCoupon() {
      this.loading = true;
      this.$api.my
        .exchangeCouponAddress({
          userId: localStorage.getItem("customerId"),
          redeCode: this.redeCode,
          password: this.password,
        })
        .then((res) => {
          this.loading = false;
          this.redeCode = "";
          this.password = "";
          if (res.errno === 200) {
            this.show = false;
            Toast.success("兑换成功");
            this.getCouponList();
          } else {
            this.show = false;
            Toast.fail(res.errmsg);
          }
        });
    },

    //修改时间
    formatDate(cellValue) {
      if (cellValue == null || cellValue == "") return "";
      var date = new Date(cellValue);
      var year = date.getFullYear();
      var month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      var hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      var minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      var seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        hours +
        ":" +
        minutes +
        ":" +
        seconds
      );
    },

    getCouponList() {
      this.$api.my
        .showCouponAddress({
          userId: localStorage.getItem("customerId"),
        })
        .then((res) => {
          if (res.errno === 200) {
            let couponClassText = res.data;
            this.couponClass = {}
            this.couponClass.noUsed = couponClassText.noUsed
            this.couponClass.hasUsed = couponClassText.hasUsed
            this.couponClass.loseUsed = couponClassText.loseUsed
            console.log(this.couponClass);

            for (let i in this.couponClass) {
              if (this.couponClass[i].length) {
                for (let j = 0; j < this.couponClass[i].length; j++) {
                  this.couponClass[i][j].time = this.formatDate(
                    this.couponClass[i][j].expireTime
                  );
                }
              }
              if (i === "hasUsed") {
                for (let j = 0; j < this.couponClass[i].length; j++) {
                  this.couponClass[i][j].status = 0;
                }
              }

              if (i === "loseUsed") {
                for (let j = 0; j < this.couponClass[i].length; j++) {
                  this.couponClass[i][j].status = 1;
                }
              }

              if (i === "noUsed") {
                for (let j = 0; j < this.couponClass[i].length; j++) {
                  this.couponClass[i][j].status = 2;
                }
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.van-toast {
  height: 1000px;
}
.bk {
  height: 100vh;
  // background-color: #f0f0f0;
}
.coupon {
  width: 100%;
  box-sizing: border-box;
  padding: 1.5rem 0 0;
  // background-color: #f0f0f0;

  .van-nav-bar {
    z-index: 100;
    .van-icon {
      color: rgb(139, 138, 138);
      font-size: 0.5rem;
    }
    .van-nav-bar__title {
      color: #333;
      letter-spacing: 2px;
    }
    .van-nav-bar__text {
      color: rgb(216, 75, 65);
      letter-spacing: 2px;
    }
  }
  /deep/ .van-tabs__wrap {
    position: fixed;
    width: 100%;
    top: 0.8rem;
    left: 0;
    z-index: 300;
  }
  .box {
    width: 100%;
    height: 90%;
    padding: 0.6rem 0 0.5rem;
    overflow: hidden;
    box-sizing: border-box;
    .box-title {
      font-size: 0.5rem;
      font-weight: 550;
      text-align: center;
      margin-bottom: 0.3rem;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 0.4rem;
      .confirm {
        width: 4rem;
        background: @ordinary_btn_border_color;
      }
    }
  }
  /deep/ .van-tabs__content {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.8rem;
    margin: 0.6rem 0 0;
    .coupon-item {
      // height: 1.5rem;
      padding: 0.2rem;

      border-radius: 0.5rem;
      // box-sizing: border-box;
      margin: 0 0 0.6rem;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
      background-color: #fff !important;
      font-size: 0.2rem;
      color: #bebebe;
      li {
        span {
          line-height: 1.2rem;
        }
      }
      .coupon-item-info {
        display: flex;
        justify-content: left;
        align-items: center;
        margin-left: 0.3rem;
        height: 1.7rem;

        // p {
        //   &:first-child {
        //     color: #d84b41;
        //     width: 30%;
        //     &.active {
        //       color: #8c8c8c;
        //     }
        //   }
        //   &:last-child {
        //     flex: 1;
        //   }
        // }

        // .coupon-name {
        //   font-size: 1rem;
        //   color: black;
        //   display: block;
        //   margin-bottom: 0.4rem;
        //   &.active {
        //     color: #8c8c8c;
        //   }
        // }
        // .coupon-time {
        //   display: block;
        // }
      }
    }
  }
}
.leftpadding {
  font-size: 0.3rem;
  color: red;
  margin-right: 10vw;
  .coupon-value {
    font-size: 0.3rem;
    font-weight: bold;
  }
  .bkurl {
    position: absolute;
    top: 0;
    right: 0;
  }
}
.bkimg {
  background: url("../../../../static/images/used.png") no-repeat;
  background-size: contain;
}
.bkimg1 {
  background: url("../../../../static/images/timeout.png") no-repeat;
  background-size: contain;
}
.text-top {
  font-size: 0.26rem;
  color: red;
}
/deep/ .van-tab--active  {
  color: @tab_title_active_color;
}
</style>