var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bk"},[_c('div',{staticClass:"coupon"},[_c('van-nav-bar',{attrs:{"title":"我的代金劵","left-arrow":"","fixed":"","z-index":101,"right-text":"兑换"},on:{"click-left":_vm.onClickLeft,"click-right":_vm.onClickRight}}),_c('van-tabs',{staticClass:"dark_vant_black",attrs:{"title-inactive-color":"#333"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.couponClass),function(item,index){return _c('van-tab',{key:index,attrs:{"title":index === 'hasUsed'
            ? '已使用'
            : index === 'loseUsed'
            ? '已失效'
            : '未使用'}},[_vm._l((item),function(coupon){return _c('ul',{key:coupon.id,staticClass:"coupon-item dark_shop_bg",class:{
            dark_used: coupon.status == 0,
            dark_timeout: coupon.status == 1,
          },style:(coupon.status == 2 ? '' : coupon.status == 0 ? _vm.bgUsed : _vm.bgTimeout)},[_c('li',{staticClass:"coupon-item-info",class:{
              bkimg: coupon.status == 0,
              bkimg1: coupon.status == 1,
            }},[_c('p',{staticClass:"leftpadding"},[_c('span',{staticClass:"coupon-value"},[_vm._v(_vm._s((coupon.discount / 100).toFixed(2)))]),_c('span',{staticClass:"coupon-unit"},[_vm._v("元")])]),_c('div',[_c('p',{staticClass:"dark_text text-top",class:{ active: item.status != 0 }},[_vm._v(" "+_vm._s(coupon.title)+" ")]),_c('p',{staticClass:"dark_text",class:{ active: item.status != 0 }},[_vm._v(" "+_vm._s("有效期截至")+" ")]),_c('p',[_vm._v(" "+_vm._s(coupon.time)+" ")]),(coupon.type !== 2)?_c('p',{staticClass:"dark_text",class:{ active: item.status != 0 }},[_vm._v(" "+_vm._s("满 : " + coupon.min / 100 + "元可用")+" ")]):_vm._e(),_c('p',{staticClass:"dark_text",class:{ active: item.status != 0 }},[_vm._v(" "+_vm._s("优惠卷类型 : " + (+coupon.type === 1 ? "满减" : coupon.type === 2 ? "代金卷" : "火锅卷"))+" ")])])])])}),(!item.length)?_c('p',{staticStyle:{"text-align":"center"}},[_c('img',{attrs:{"src":require("../../../../static/images/error.png"),"alt":"无数据占位图","width":"50%"}})]):_vm._e()],2)}),1),_c('van-popup',{style:({ height: '5rem', width: '80%' }),attrs:{"round":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"box"},[_c('p',{staticClass:"box-title"},[_vm._v("兑换")]),_c('van-field',{attrs:{"label":"兑换码","placeholder":"请输入兑换码","label-width":"1rem"},model:{value:(_vm.redeCode),callback:function ($$v) {_vm.redeCode=$$v},expression:"redeCode"}}),_c('van-field',{attrs:{"label":"密码","placeholder":"请输入密码","label-width":"1rem"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('div',{staticClass:"btn"},[_c('van-button',{staticClass:"confirm",attrs:{"type":"danger","round":"","size":"small","loading":_vm.loading},on:{"click":function($event){return _vm.exchangeCoupon()}}},[_vm._v("确认兑换")])],1)],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }